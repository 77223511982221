.NotFound {
	width: 100%;
	height: calc(var(--vh) * 100);
	padding: 20px;
	background-image: url(../img/components/mobile-only/sp-only-toribackground.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
	&__block {
		margin-bottom: 20px;
	}
}