.LoginUser {
	height: 90vh;
	overflow-y: scroll;

	img {
		width: 100%;
	}

	&__block {
		margin: auto;
		max-width: 464px;
	}

	&__header {
		height: 40px;
		img {
			width: auto;
			height: 40px;
		}
		margin-top: 20px;
		margin-bottom: 40px;
	}

	&__buttons {
		margin-top: 30px;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	&__logoutButton {
		margin-top: 20px !important;
	}
}

.OrderTable {
	&__hr {
		margin-top: 10px;
		height: 2px;
		background-color: #ccc;
		width: 100%;
	}
	&__order {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	&__header {
		margin-top: 10px;
		margin-bottom: 10px;
		word-wrap: break-word;
	}
	&__details{
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	&__thumbnail {
		width: 30%;
		margin-right: 10px;
	}
}