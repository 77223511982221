.Help {
	min-height: 100vh;
	padding: 20px;
	background-image: url(../img/components/start/background.png);
	background-size: cover;
	background-repeat: repeat;
	background-position: center;

	&__expander {
		font-size: 14px;
		.amplify-flex {
			align-items: center;
		}
		img {
			width: 25px;
		}

		img.Help__img {
			width: 100%;
		}
	}

	&__expander-sub {
		margin-top: 20px;
	}
}