.GenerateLoading {
	text-align: center;
	position: relative;
	margin-left: 20px;
	margin-right: 20px;

	&__alert {
		padding: 2rem;
		border-radius: 5px;
		background-color: white;

		.amplify-loader {
			margin-top: 10px;
		}

		.amplify-alert__heading {
			margin-bottom: 5px;
			font-size: 1.3rem;
			font-weight: 700;
			line-height: 2.2;
		}
		text-align: center;

	}

	&__img {
		padding: 20px;
		img {
			width: 100%;
		}
	}

	&__buttons {
		display: flex;
		justify-content: center;
		margin-top: 10px;
		gap: 6px 4px; 
	}
}
