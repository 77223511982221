.SideBar__item {
	cursor: pointer;
}
[data-amplify-authenticator]{
	margin: auto;
	top: 0;
	bottom: 0;
	position: absolute;
	left: 0;
	right: 0;
}