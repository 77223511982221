.Registration {
	height: 90vh;
	overflow: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	&__logo img.Registration__logo-img {
		width: 50%;
	}

	img {
		width: 100%;
	}

	&__block {
		margin: auto;
		max-width: 464px;
		background-color: white;
		padding: 20px;
		border-radius: 10px;
	}

	&__header {
		height: 40px;
		img {
			width: auto;
			height: 40px;
		}
		margin-top: 20px;
	}

	&__buttons {
		margin-top: 30px;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	&__contents {
		display: flex;
		justify-content: center;
		p {
			width: 70%;
			text-align: left;
		}
		margin-top: 10px;
		margin-bottom: 30px;
	}

	&__notice {
		font-size:10px;
		p {
			width: 90%;
			text-align: left;
		}

	}

	&__terms {
		display: flex;
    justify-content: center;
    align-items: center;
	}

	&__logoutButton {
		margin-top: 20px !important;
	}

	.amplify-checkboxfield {
		display: inline-block;
		padding-right: 8px;
	}
	a {
		color: #828bdf;
		font-weight: 700;
		text-decoration: solid;
	}

	&__button-line{
		border: none !important;
		padding: 0 !important;
		background-color: #06C755;
		color: white;
		border-radius: 5px;
		width: 100%;
		display: flex;
		justify-content: left;
		align-items: center;
		&-image {
			border-right: solid 1px rgba(0,0,0,0.08);
			height: 100%;
			img {
				width: 60px;
				margin:auto;
				display: block;
				padding-left:10px;
				padding-right:10px;
			}
		}
		p {
			text-align: center;
			width: 100%;
		}
		&:disabled {
			background-color: white;
			color: rgb(30 30 30 / 20%);
			border: 1px solid rgb(229 229 229 / 60%) !important;
			box-sizing: border-box;
		}
	}

	&__button-google{
		border: none !important;
		padding: 0 !important;
	}

	&__button-close{
		margin-top: 20px;
		background-color: white !important;
	}
}

.OrderTable {
	&__hr {
		margin-top: 10px;
		height: 2px;
		background-color: #ccc;
		width: 100%;
	}
	&__order {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	&__header {
		margin-top: 10px;
		margin-bottom: 10px;
	}
	&__details{
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	&__thumbnail {
		width: 30%;
		margin-right: 10px;
	}
}