.TextInput {
	text-align: center;
	position: relative;

	.chrome-picker{
		width: 100% !important;
	}

	h2 {
		padding: 10px;
	}

	&__close {
		position: absolute;
    right: -18px;
    top: -33px;
    z-index: 2;
		img {
			width: 40px;
		}
	}
	.amplify-alert__body {
		text-align: left;
	}

	.amplify-radiogroup {
		flex-direction: row;
		flex-wrap: wrap;
	}
	span.amplify-flex.amplify-radio__button {
		display: none;
	}
	input[type="radio"].amplify-visually-hidden {
		position: relative;
		overflow: auto;
    clip: unset;
		width: auto;
		height: auto;
	}

	input[type="radio"]:before {
		content: " ";
    display: inline-block;
    background-size: contain;
    width: 50px;
    height: 50px;
		fill: none;
		background-repeat: no-repeat;
	}
	input[type=radio]:checked::before{
    border: 3px solid #c8c3f0;
    box-sizing: border-box;
	}
	input[name=vertical-align][value="top"]:before{
		background-image: url(../img/components/text-clip-setting/vertical-top.png);
	}
	input[name=vertical-align][value="center"]:before{
		background-image: url(../img/components/text-clip-setting/vertical-center.png);
	}
	input[name=vertical-align][value="bottom"]:before{
		background-image: url(../img/components/text-clip-setting/vertical-bottom.png);
	}

	input[name=text-align][value="left"]:before{
		background-image: url(../img/components/text-clip-setting/align-left.png);
	}
	input[name=text-align][value="center"]:before{
		background-image: url(../img/components/text-clip-setting/align-center.png);
	}
	input[name=text-align][value="right"]:before{
		background-image: url(../img/components/text-clip-setting/align-right.png);
	}
	input[name=fontname][value="corporate-logo-ver2"]:before{
		background-image: url(../img/components/text-clip-setting/corporate-logo-ver2.png);
	}
	input[name=fontname][value="source-han-sans-japanese"]:before{
		background-image: url(../img/components/text-clip-setting/source-han-sans-japanese.png);
	}
	input[name=fontname][value="dela-gothic-one"]:before{
		background-image: url(../img/components/text-clip-setting/dela-gothic-one.png);
	}
	input[name=fontname][value="kurobara-gothic"]:before{
		background-image: url(../img/components/text-clip-setting/kurobara-gothic.png);
	}
	input[name=fontname][value="shippori-mincho-b1"]:before{
		background-image: url(../img/components/text-clip-setting/shippori-mincho-b1.png);
	}
	input[name=fontname][value="hot-gfkaishokk"]:before{
		background-image: url(../img/components/text-clip-setting/hot-gfkaishokk.png);
	}

	input[name=fontname][value="azo-sans-uber"]:before{
		background-image: url(../img/components/text-clip-setting/azo-sans-uber.png);
	}
	input[name=fontname][value="dnp-shuei-gothic-kin-std"]:before{
		background-image: url(../img/components/text-clip-setting/dnp-shuei-gothic-kin-std.png);
	}
	input[name=fontname][value="ta-kaku-shadow"]:before{
		background-image: url(../img/components/text-clip-setting/ta-kaku-shadow.png);
	}
	input[name=fontname][value="fot-udkakugoc80-pro"]:before{
		background-image: url(../img/components/text-clip-setting/fot-udkakugoc80-pro.png);
	}
	input[name=fontname][value="fot-chiaro-std"]:before{
		background-image: url(../img/components/text-clip-setting/fot-chiaro-std.png);
	}
	input[name=fontname][value="akabara-cinderella"]:before{
		background-image: url(../img/components/text-clip-setting/akabara-cinderella.png);
	}
	input[name=fontname][value="kokuryu"]:before{
		background-image: url(../img/components/text-clip-setting/kokuryu.png);
	}
	input[name=fontname][value="ab-megadot9"]:before{
		background-image: url(../img/components/text-clip-setting/ab-megadot9.png);
	}
	input[name=fontname][value="ta-mincho-gf-01"]:before{
		background-image: url(../img/components/text-clip-setting/ta-mincho-gf-01.png);
	}
	input[name=fontname][value="zen-maru-gothic"]:before{
		background-image: url(../img/components/text-clip-setting/zen-maru-gothic.png);
	}
	input[name=fontname][value="vdl-linegrpop-shadow"]:before{
		background-image: url(../img/components/text-clip-setting/vdl-linegrpop-shadow.png);
	}
	input[name=fontname][value="kaisei-decol"]:before{
		background-image: url(../img/components/text-clip-setting/kaisei-decol.png);
	}

	&__buttons {
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		gap: 10px;
	}

	&__info {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
		img {
			width: 30px;
			height: 30px;
			margin-right: 5px;
		}
	}
}
