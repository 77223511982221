.TextClipInfo {
	text-align: center;
	position: relative;

	img {
		width: 100%;
	}

	.swatches-picker {
		width: auto !important;
	}

	h2 {
		padding: 10px;
	}

	&__close {
		position: absolute;
    right: -18px;
    top: -33px;
    z-index: 2;
		img {
			width: 40px;
		}
	}
	.amplify-alert__body {
		text-align: left;
	}

	.amplify-radiogroup {
		flex-direction: row;
	}
	span.amplify-flex.amplify-radio__button {
		display: none;
	}
	input[type="radio"].amplify-visually-hidden {
		position: relative;
		overflow: auto;
    clip: unset;
		width: auto;
		height: auto;
	}

	input[type="radio"]:before {
		content: " ";
    display: inline-block;
    background-size: contain;
    width: 50px;
    height: 50px;
		fill: none;
		background-repeat: no-repeat;
	}
	input[type=radio]:checked::before{
    border: 3px solid #c8c3f0;
    box-sizing: border-box;
	}
	input[name=vertical-align][value="top"]:before{
		background-image: url(../img/components/text-clip-setting/vertical-top.png);
	}
	input[name=vertical-align][value="center"]:before{
		background-image: url(../img/components/text-clip-setting/vertical-center.png);
	}
	input[name=vertical-align][value="bottom"]:before{
		background-image: url(../img/components/text-clip-setting/vertical-bottom.png);
	}

	input[name=text-align][value="left"]:before{
		background-image: url(../img/components/text-clip-setting/align-left.png);
	}
	input[name=text-align][value="center"]:before{
		background-image: url(../img/components/text-clip-setting/align-center.png);
	}
	input[name=text-align][value="right"]:before{
		background-image: url(../img/components/text-clip-setting/align-right.png);
	}

	&__buttons {
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		gap: 10px;
	}

	&__info {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
		img {
			width: 30px;
			height: 30px;
			margin-right: 5px;
		}
	}
}
