.Commerce {
	padding: 20px;
	background-image: url(../img/components/start/background.png);
	background-size: cover;
	background-repeat: repeat;
	background-position: center;
	&__block {
		padding: 20px;
		background-color: white;
		border-radius: 10px;
	}

	.amplify-table__td {
		padding: 10px;
	}
}