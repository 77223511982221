.CameraFailure {
	margin: 20px;

	&::before {
		width: 100vw;
		content: "";
		background-image: url(../../img/components/start/main_up.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position-y: top;
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		z-index: 1;
	}

	&::after {
		content: "";
		width: 100vw;
		height: 100%;
		background-image: url(../../img/components/start/main_under.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		background-position-y: bottom;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 1;
	}

	&__message {
		padding: 20px;
		z-index: 3;
		position: fixed;
		width: 90%;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		background-color: white;
		height: fit-content;
		border-radius: 5px;
	}

	&__buttons {
		display: flex;
		justify-content: center;
		align-items: flex-end;
	}
}
