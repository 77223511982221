.Loading {
  width: 100vw;
  height: calc(var(--vh) * 100);
  position: absolute;
  background-color: white;

  img {
    width: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}