.MobileOnly {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  background-image: url(../img/components/mobile-only/pc-background.png);
  background-size: cover;
  overflow: hidden;
  display: flex;
}

.MobileOnly__content {
  overflow: hidden;
  margin: auto;
  padding: 0 20px;
  justify-content: center;
  align-items: center;

  img {
    width: 100%
  }
}