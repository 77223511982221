.Preview {
	text-align: center;
	position: relative;
	padding-top: 20px;
	z-index: 100;

	#generate-preview-image canvas {
		height: auto !important;
	}

	&__overlay {
		position: relative;
		&::before {
			overflow: hidden;
			width: 100vw;
			content: "";
			background-image: url(../img/components/start/main_up.png);
			background-size: contain;
			background-repeat: no-repeat;
			background-position-y: top;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			z-index: 1;
		}

		&::after {
			overflow: hidden;
			content: "";
			width: 100vw;
			height: 100%;
			background-image: url(../img/components/start/main_under.png);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			background-position-y: bottom;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
		}

	}

	&__logo {
		position: absolute;
		margin-bottom: 10px;
		top: 10px;
		left: 40px;
		img.Preview__logo-data {
			width: 70px;
		}
	}

	&__close {
		position: absolute;
		top: -10px;
		right: -10px;
		width: 50px;
		height: 50px;
		background-color: #ef8784;
	}

	&__image {
		position: relative;
		img {
			width: 100%;
		
		}
	}
	&__image-block {
			width: 80%;
			margin: auto;
	}

	&__front-overlay {
		margin: 0;
		top: 0;
  	position: absolute;
		width: 80%;
	}

	&__download {
		position: absolute;
		font-size: 13px;
		bottom: 17px;
		border: solid 3px #ef8784;
		background-color: white;
		padding: 10px;
		margin: auto;
		left: 0;
		right: 0;
		border-radius: 10px;
		width: 70%;
	}

	&__back {
		position: absolute;
		right: 2px;
    top: 3px;
		z-index: 200;
		img {
			width: 100%;
			background: white;
			border-radius: 50px;
			padding: 2px;
			width: 40px;
		}
	}

	#preview-image {
		outline: solid 2px #000;
    border: solid 6px #c8c3f0;
		line-height: 1px;
		margin-bottom: 10px;
		position: relative;
		border-radius: 5px;
		z-index: 101;
	}
	&__alert {
		width: 80%;
		margin: auto;
		margin-bottom: 10px;
		background-color: #fae293;
		outline: solid 2px #fae293;
		color: #5461db;
    font-weight: 800;
		border-radius: 5px;
	}

}

.PreviewButtons {
	margin: 20px;
	padding: 20px;
	img {
		width: 100%;
	}

	&__shares {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		flex: 2;
		align-items: center;
		padding-left: 20px;
		padding-right: 20px;
	}

	&__share {
		img {
			width: 40px;
		}
	}

}

.GenerateButtons {
	display: flex;
	justify-content: center;
	margin: 10px;
	flex-wrap: wrap;
	gap: 5px;
	&__sample {
		border-radius: 5px;
	}
	&__block {
		margin-right: 5px;
		min-width: 45%;
		width: 50%;
		flex: 2;
	}

	img {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
	&__done {
		margin-top: 10px;
		margin-bottom: 10px;
		background-color: #fa268b;
	}
	&__done.amplify-button--primary:active {
		background-color: #a2195b;
	}
	
}

.arrows {
	outline: solid 2px #000;
	background: white;
  border-radius: 5px;
  border: 6px solid #c8c3f0;
	font-size: 1.3rem;
	font-weight: 700;
	line-height: 2.2;
	margin-bottom: 10px;

	&__block {
  	padding: 20px;
		padding-bottom: 40px;
		position: relative;
		display: flex;
		justify-content: center;
		flex-direction: column;
		gap: 10px;
	}

	&__block::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
		border: 2px solid black;
	}

	&__top {
		display: flex;
		justify-content: center;
	}

	&__bottom {
		display: flex;
		justify-content: center;
		flex-direction: row;
		gap: 10px;
	}


	&__button {
		position: relative;
		width: 50px;
		height: 50px;
		border-radius: 25px;
		z-index: 3;

		&.arrows_button-top{
			&:before{
				content: url( 'data:image/svg+xml;utf-8,<svg fill="%23fff" xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 24 24"><path d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" fill="currentColor"></path></svg>' );
			}
		}

		&.arrows_button-left{
			&:before{
				content: url( 'data:image/svg+xml;utf-8,<svg fill="%23fff" xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 24 24"><path d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" fill="currentColor"></path></svg>' );
			}
		}

		&.arrows_button-down{
			&:before{
				content: url( 'data:image/svg+xml;utf-8,<svg fill="%23fff" xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 24 24"><path d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" fill="currentColor"></path></svg>' );
			}
		}

		&.arrows_button-right{
			&:before{
				content: url( 'data:image/svg+xml;utf-8,<svg fill="%23fff" xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 24 24"><path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" fill="currentColor"></path></svg>' );
			}
		}

		&.arrows_button-plus{
			&:before{
				content: url( 'data:image/svg+xml;utf-8,<svg fill="%23fff" xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 24 24"><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" fill="currentColor"></path></svg>' );
			}
		}

		&.arrows_button-minus{
			&:before{
				content: url( 'data:image/svg+xml;utf-8,<svg fill="%23fff" xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 24 24"><path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" fill="currentColor"></path></svg>' );
			}
		}

		&:before {
			display: inline-block;
			width: 50px;
			height: 50px;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 2;
			background-color: white;
			border-radius: 25px;
			border: solid 2px #000;
			content: "";
		}

		&:after {
			z-index: 1;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		
			width: 100%;
			height: 100%;
		
			content: "";
			-webkit-transition: all 0.3s;
			transition: all 0.3s;
			-webkit-transform: translate3d(0, 0.35rem, -1rem);
			transform: translate3d(0, 0.35rem, -1rem);
		
			border: 2px solid #000;
			border-radius: inherit;
			background: #c8c3f0;
			-webkit-box-shadow: 0 0.2rem 0 0 rgba(0, 0, 0, 0.2);
			box-shadow: 0 0.2rem 0 0 rgba(0, 0, 0, 0.2);

		}

		&:active {
			-webkit-transform: translate(0rem, 0.15rem);
			transform: translate(0rem, 0.15rem);
		}

		&:active:before {
			-webkit-transform: translate3d(0, 0.25rem, -1rem);
			transform: translate3d(0, 0.25rem, -1rem);
			-webkit-box-shadow: 0 0.15rem 0 0 rgba(0, 0, 0, 0.2);
			box-shadow: 0 0.15rem 0 0 rgba(0, 0, 0, 0.2);
		}
	}
}
