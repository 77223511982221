.Start {
	width: 100vw;
	height: calc(var(--vh) * 100);
	overflow: hidden;
	background-color:#fcefaa ;
	background-size: cover;
	background-repeat: repeat;
	background-position: center;
	position: relative;

	&::before {
		width: 100vw;
		content: "";
		background-image: url(../img/components/start/main_up.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position-y: top;
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		z-index: 1;
	}

	&::after {
		content: "";
		width: 100vw;
		height: 100%;
		background-image: url(../img/components/start/main_under.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		background-position-y: bottom;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 1;
	}

	img {
		width: 100%;
	}

	&__block {
		margin: auto;
		position: relative;
		z-index: 2;
		max-width: 464px;
	}

	&__logo {
		padding: 15px 5% 0 5%;

		img {
			max-width: 25vh;
		}
	}


	&__contents {
		position: relative;

		&-background {
			margin: 10% 5%;

			img {
				border-radius: 5px;
				height: auto;
			}
		}

		&::before {
			content: ' ';
			background-image: url(../img/components/start/vv_logo.png);
			background-size: contain;
			background-repeat: no-repeat;
			width: 39%;
			height: 34px;
			position: absolute;
			top: -41px;
			right: -14px;
		}
	}


	&__items {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		padding: 20%;
	}

	&__item {
		margin-bottom: 20px;
		width: 100%;
	}

	&__button {
		background-color: transparent;
		border: none;
		border-radius: 5px;
		height: 70px;
		width: 100%;
		display: block;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	&__start-button {
		background-image: url(../img/components/start/start.png);
	}

	&__start-button:hover,
	&__start-button:focus {
		background-image: url(../img/components/start/start-hover.png);
	}
	&__start-button:disabled {
		background-image: url(../img/components/start/start-disabled.png);
	}

	&__registration-button {
		background-image: url(../img/components/start/registration.png);
	}
	&__registration-button:hover,
	&__registration-button:focus {
		background-image: url(../img/components/start/registration-hover.png);
	}
	&__registration-button:disabled {
		background-image: url(../img/components/start/registration-disabled.png);
	}

	&__terms {
		display: flex;
    justify-content: center;
    align-items: center;
		background-color: #fae293;
		height: 40px;
		width: 100%;
		border-radius: 5px;
		line-height: 2.2;
	}

	.amplify-checkboxfield {
		display: inline-block;
		padding-right: 8px;
	}
	a {
		color: #5461db;
		font-weight: 800;
		text-decoration: solid;
	}
	&__agree {
		background: none;
		border: none;
		color: black;
	}
	&__dot {
		text-align: right;
		img {
			width: 50px;
		}
	}
}