.OriginalButton {
	width: 100%;
	font-size: 1.3rem;
	font-weight: 700;
	line-height: 2.2;
	position: relative;
	display: inline-block;
	padding: 1rem 4rem;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	text-align: center;
	vertical-align: middle;
	text-decoration: none;
	letter-spacing: 0.1em;
	color: #212529;
	border-radius: 5px;
	margin-bottom: 20px;
	padding: 0;

	&:before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	
		width: 100%;
		height: 100%;
	
		content: "";
		-webkit-transition: all 0.3s;
		transition: all 0.3s;
		-webkit-transform: translate3d(0, 0.55rem, -1rem);
		transform: translate3d(0, 0.55rem, -1rem);
	
		border: 2px solid #000;
		border-radius: inherit;
		background: #c8c3f0;
		-webkit-box-shadow: 0 0.3rem 0 0 rgba(0, 0, 0, 0.2);
		box-shadow: 0 0.3rem 0 0 rgba(0, 0, 0, 0.2);

	}

	&:active {
		-webkit-transform: translate(0rem, 0.35rem);
		transform: translate(0rem, 0.35rem);
	}

	&:active:before {
		-webkit-transform: translate3d(0, 0.25rem, -1rem);
		transform: translate3d(0, 0.25rem, -1rem);
		-webkit-box-shadow: 0 0.35rem 0 0 rgba(0, 0, 0, 0.2);
		box-shadow: 0 0.35rem 0 0 rgba(0, 0, 0, 0.2);
	}

	&__text {
		position: relative;
    display: block;
    -webkit-transition: all .3s;
    transition: all .3s;
    border: 2px solid #000;
    border-radius: inherit;
    background: white;
	}
}