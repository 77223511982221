$itemHeaderSize: 55px;
.ControllerHeader {
	position: fixed;
  top: 0;
}

.UserButton {
	background-color: transparent;
	width: 57px;
	margin-top: 15px;
	margin-left: 10px;
	border: none;
	img {
		width: 100%;
		min-width: 40px;
	}
}

.InfoButton {
	background-color: transparent;
	width: 57px;
	margin-top: 15px;
	margin-left: 10px;
	border: none;
	position: fixed;
	top: 0;
  right: 25px;
	img {
		width: 100%;
		min-width: 40px;
	}
}

.ClipTextButton {
	background-color: transparent;
	width: 57px;
	margin-top: 15px;
	margin-left: 10px;
	border: none;
	position: fixed;
	top: 0;
  right: 160px;
	img {
		width: 100%;
		min-width: 40px;
	}
}

.PhotoTypeButton {
	background-color: transparent;
	width: 57px;
	margin-top: 15px;
	margin-left: 10px;
	border: none;
	position: fixed;
	top: 0;
  right: 90px;
	img {
		width: 100%;
		min-width: 40px;
	}
}

.Controller {
	display: flex;
	align-items: end;
	flex-wrap: wrap;
	justify-content: center;
	padding-top: 20px;
	overflow: hidden;
	width: 100%;
	padding-bottom: 30px;

	&__buttons {
		display: block;
		width: 55px;
		height: 69px;
		margin-bottom: -13px;
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: 90% auto
	}

	&__carousel {
		margin-bottom: -3px;
		background-image: url(../img/components/controller/stamp.png);
	}

	&__shutter {
		margin: 0% 13%;
		// TODO: 背景画像の場所
		background-image: url(../img/components/controller/button.png);
		width: 80px;
		height: 80px;
	}

	&__record {
		margin: 0% 13%;
		background-image: url(../img/components/controller/video.png);
		width: 80px;
		height: 80px;
	}

	&__recording {
		margin: 0% 13%;
		background-image: url(../img/components/controller/video-stop.png);
		width: 80px;
		height: 80px;
	}

	&__switch-camera {
		// TODO: 背景画像の場所
		background-image: url(../img/components/controller/switching.png);
	}

	&__text-input {
		// TODO: 背景画像の場所
		background-image: url(../img/components/controller/text-input.png);
	}

	&__control-right {
		margin-bottom: 12px;
		display: flex;
		flex-direction: column;
		gap: 30px;
	}


	&__wrapper {
		display: flex;
		position: fixed;
		flex-direction: column;
		width: 100%;
		bottom: 0;
	}

	&__under-control {
		margin-bottom: -70px;
	}

	&__delete-stamp {
		background-size: contain;
		background-repeat: no-repeat;
		content: ' ';
		width: 65px;
		margin-left: 20px;
		margin-top: -160px;

		img {
			width: 100%;
		}
	}

}


.ItemPanel {
	height: 350px;
	background-color: rgb(61 61 61 / 80%);
	position: absolute;
	bottom: 0;
	width: 100%;

	&__delete-stamp {
		width: 40px;
		height: 40px;
		background-color: #ef8784;
	}
}

.ItemPanelItems {
	overflow: scroll;
	height: 295px;
}

.ItemPanelHeader {
	height: $itemHeaderSize;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #2e2e33;


	&__close {
		min-width: $itemHeaderSize;
		width: $itemHeaderSize;
		margin: auto;

		img {
			width: 15px;
		}
	}

	&__select-type {
		height: 100%;
		background-color: rgba(46, 46, 51, 1.0);
		padding: 0 20px;
		overflow-x: scroll;
		white-space: nowrap;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
	}

	&__type {
		color: white;
		font-family: "M PLUS 1p", sans-serif;
		font-weight: 800;
		font-style: normal;
		padding: 10px 10px;

		&.active {

			span {
				padding-bottom: 4px;
				border-bottom: 2px solid #ef8784;
			}
		}
	}
}

.ItemList {
	margin-top: 10px;
	display: flex;
	flex-wrap: wrap;
	margin-left: 20px;
	margin-bottom: 20px;
	align-items: center;
	// これを外すとメニューの横スクロールができなくなります
	position: relative;

	&__item {
		margin: 5px;
		max-width: 20%;
		position: relative;

		&--frame {
			max-width: 28%;
		}
	}

	&__key {
		position: absolute;
    width: 15px !important;
    right: 0;
    bottom: 0;
	}

	&__item img {
		width: 100%;
	}

}

.ItemNotice {
	p {
		color: white;
		padding: 10px;
		margin: 0;
		background-color: #1111118d;
	}
}
.MyItem {
	padding: 20px 0px;
	button {
		background-color: white
	}
}

.ItemPanelClear {
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	&__image {
		background-color: rgba(46, 46, 51, 1.0);
		height: 100%;
		display: flex;
		width: $itemHeaderSize;
		margin: auto;
		padding-left: 5px;
		padding-right: 5px;

		img {
			width: 23px;
			margin: auto;
		}
	}
	&__text {
		margin-top: 4px;
	}
}