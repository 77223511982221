.PreviewVideo {
	text-align: center;
	position: relative;

	&__logo {
		position: absolute;
		margin-bottom: 10px;
		top: 10px;
		left: 40px;
		img.Preview__logo-data {
			width: 70px;
		}
	}

	&__video {
		height: auto !important;
		border-radius: 10px;
	}

	&__close {
		position: absolute;
		top: -10px;
		right: -10px;
		width: 50px;
		height: 50px;
		background-color: #ef8784;
	}

	&__image {
		position: relative;
		img {
			border-radius: 10px;
			width: 80%;
		}
	}

	&__back {
		position: absolute;
		right: 2px;
		top: 3px;
		z-index: 100;
		img {
			width: 100%;
			background: white;
			border-radius: 50px;
			padding: 2px;
			width: 40px;
		}
	}

}

.PreviewButtons {
	img {
		width: 100%;
	}

	&__shares {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		padding-left: 20px;
		padding-right: 20px;
	}

	&__share {
		img {
			width: 40px;
		}
	}
}