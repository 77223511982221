.ConfirmReset {
	text-align: center;
	position: relative;
	padding-top: 20px;

	&__alert {
		border-radius: 5px;

		.amplify-alert__heading {
			margin-bottom: 5px;
		}
		text-align: left;
	}

	&__alert ul {
		margin-top: 2px;
		list-style: decimal;
		border-radius: 5px;
	}

	&__buttons {
		display: flex;
		justify-content: center;
		margin-top: 10px;
		gap: 6px 4px; 
	}
}
