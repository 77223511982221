.App {
  text-align: center;
  overflow: hidden;
}

body {
  min-height: calc(var(--vh) * 100) !important;
}

canvas {
  touch-action: none;
  width: 100% !important;
  height: calc(var(--vh) * 100) !important;
  display: block;
}

video {
  width: 100% !important;
  height: calc(var(--vh) * 100) !important;
}

.canvas-block {
  width: 100% !important;
  height: calc(var(--vh) * 100) !important;
}