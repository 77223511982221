.VideoBlack {
	height: 90vh;
	overflow: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color:#fcefaa ;
	background-size: cover;
	background-repeat: repeat;
	background-position: center;
	position: relative;
	z-index: 2;

	&::before {
		width: 100vw;
		content: "";
		background-image: url(../../img/components/start/main_up.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position-y: top;
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		z-index: 1;
	}

	&::after {
		content: "";
		width: 100vw;
		height: 100%;
		background-image: url(../../img/components/start/main_under.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		background-position-y: bottom;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 1;
	}

	&__close {
		position: absolute;
		right: 10px;
		top: 10px;
		z-index: 2;
	}

	.VideoBlack__close img {
		width: 40px;
	}


	&__logo img.VideoBlack__logo-img {
		width: 50%;
	}

	img {
		width: 100%;
	}

	&__block {
		margin: auto;
		max-width: 464px;
		position: relative;
		z-index: 2;
		background-color: white;
		padding: 20px;
		width: 100%;
		border-radius: 10px;
	}

	&__header {
		img {
			width: auto;
			height: 40px;
		}
		margin-top: 20px;
		margin-bottom: 40px;
	}

	&__buttons {
		margin-top: 30px;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	&__button {
		margin-top: 10px !important;
		background-color: white !important;
		width: 100%;
		text-decoration: none;

		button {
			width: 100%;
		}
	}

	.amplify-checkboxfield {
		display: inline-block;
	}

	&__button-close{
		margin-top: 20px;
		background-color: white !important;
	}
}
