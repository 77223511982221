.CodeForm {
	text-align: center;
	position: relative;

	h2 {
		padding: 10px;
	}

	&__close {
		position: absolute;
    right: -18px;
    top: -33px;
    z-index: 2;
		img {
			width: 40px;
		}
	}
	.amplify-alert__body {
		text-align: left;
	}
}
