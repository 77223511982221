.AngleNotice {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 100;
  background-image: url(./img/components/mobile-only/pc-background.png);
  background-size: cover;
  overflow: hidden;
  display: flex;
}

.AngleNotice__content {
  overflow: hidden;
  margin: auto;
  padding: 20px 0;
  justify-content: center;
  align-items: center;

  img {
    width: 60%;
  }
}